<template>
  <div class="p-grid">
    <div class="p-col-1">{{ id }}</div>
    <div class="p-col-2">{{ formatDate(startDate) }}</div>
    <div class="p-col-2">{{ product }}</div>
    <div class="p-col-2">{{ productVersion }}</div>
    <div class="p-col-2"><span v-if="isMasterRecord" class="p-tag">Master Record</span></div>
    <div class="p-col-1">{{ licenseCount }}</div>
    <div class="p-col"><base-button @click="viewDetails" small label="View details"></base-button></div>
  </div>
</template>

<script>
import { useFormatter } from "../../hooks/formatter";

export default {
  props: {
    id: {
      type: Number
    },
    startDate: {
      type: Date,
    },
    product: {
      type: String,
    },
    productVersion: {
      type: String,
    },
    expirationDate: {
      type: Date,
    },
    isMasterRecord: {
      type: Boolean
    },
    licenseCount: {
      type: Number,
      default: 0
    }
  },
  emits: ['view-details', 'viewDetails'],
  setup(props, {emit}) {
    const { formatDate } = useFormatter();

    function viewDetails() {
      emit('view-details', props.id);
    }

    return {      
        formatDate,
        viewDetails
    };
  },
};
</script>
