<template>
  <h2>Generated licenses for license settings with id {{ licenseSettingsId }}</h2>
  <div class="p-grid">
    <div class="p-col-1"><strong>Id</strong></div>
    <div class="p-col-3"><strong>Machine key</strong></div>
    <div class="p-col-1"><strong>Valid from</strong></div>
    <div class="p-col-1"><strong>Expiration date</strong></div>
    <div class="p-col-1"><strong>Product Version</strong></div>
    <div class="p-col"></div>
  </div>
  <license-overview-item
    v-for="lic in licenses"
    :key="lic.id"
    :id="lic.id"
    :valid-from="lic.validFromDate"
    :expiration-date="lic.expirationDate"
    :machine-key="lic.localMachineCode"
    :product-version="lic.licenseSettings.productVersion"
    class="p-mt-1"
  ></license-overview-item>
</template>

<script>
import LicenseOverviewItem from "./LicenseOverviewItem.vue";
export default {
  components: { LicenseOverviewItem },
  props: {
      licenses: {
          type: Object,
          required: true,
      }
  },
  setup(props) {
    const licenseSettingsId = (props.licenses && props.licenses.length > 0) ? props.licenses[0].licenseSettingsId : null;

    return {
      licenseSettingsId
    }
  }
};
</script>
