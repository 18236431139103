<template>
  <ul>
    <li v-for="(code, index) in codes" :key="code">
      {{ code }}
      <a v-if="!isReadonly" @click="removeEntry(index)" title="Remove entry"><i class="pi pi-times-circle"></i></a>
    </li>
  </ul>
</template>

<script>
export default {
  props: {
    codes: {
      type: Array,
      required: true,
      default: () => [],
    },
    isReadonly: {
      type: Boolean,
      default: false
    }
  },
  emits: ["remove"],
  setup(_, { emit }) {
    function removeEntry(index) {
      emit("remove", index);
    }

    return {
      removeEntry,
    };
  },
};
</script>
