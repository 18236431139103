<template>
  <div class="p-grid">
    <div class="p-col-1">{{ id }}</div>
    <div class="p-col-3">{{ machineKey }}</div>
    <div class="p-col-1">{{ formatDate(validFrom) }}</div>
    <div class="p-col-1">{{ formatDate(expirationDate) }}</div>    
    <div class="p-col-1">{{ productVersion }}</div>    
    <div class="p-col">
      <base-button :to="'/licenses/' + id" small link label="View details"></base-button>
    </div>
  </div>
</template>

<script>
import { useFormatter } from "../../hooks/formatter";

export default {
  props: {
    id: {
      type: Number,
    },
    validFrom: {
      type: Date,
    },
    expirationDate: {
      type: Date,
    },
    machineKey: {
      type: String,
    },
    productVersion: {
      type: String
    }
  },
  setup() {
      const { formatDate } = useFormatter();

      return {
          formatDate
      }
  }
};
</script>
