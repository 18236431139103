<template>
  <h1>License settings of the subscription</h1>
  <div class="p-grid">
    <div class="p-col-1"><strong>Id</strong></div>
    <div class="p-col-2"><strong>License settings start date</strong></div>
    <div class="p-col-2"><strong>Product</strong></div>
    <div class="p-col-2"><strong>Product version</strong></div>
    <div class="p-col-2"><strong>Master record</strong></div>    
    <div class="p-col-1"><strong>Generated liceneses</strong> </div>
    <div class="p-col"><strong>View details</strong>  </div>    
</div>
   <license-settings-overview-item 
    v-for="ls in licenseSettings" 
    :key="ls.id"
    :start-date="ls.startDate"
    :expiration-date="ls.expirationDate"
    :product="ls.product"
    :product-version="ls.productVersion"
    :is-master-record="ls.isMasterRecord"
    :license-count="ls.licenseCount"
    :id="ls.id"
    @view-details="viewDetails">
   </license-settings-overview-item>
</template>

<script>
import LicenseSettingsOverviewItem from './LicenseSettingsOverviewItem.vue';

export default {
    components: {
        LicenseSettingsOverviewItem
    },
    props: {
        licenseSettings: {
            type: Array,
        }
    },          
  emits: ['view-details', 'viewDetails'],
  setup(_, {emit}) {
      
    function viewDetails(id) {
      emit('view-details', id);
    }

    return {
        viewDetails,
    }
  }
}
</script>